<template>
  <div>

  </div>
</template>
<script>
  import { store } from 'src/store'

  export default {
    async mounted () {
      //await store.initStatusCodes()
      await store.initMundial()
      store.colsDict = {}
      store.idBeDict = {}
      store.idSnDict = {}
      store.idDeviceDict = {}
      store.idSubProductDict = {}
      store.idDemoDict = {}
      store.idCountryDict = {}
      store.idLangDict = {}
      store.idPlatformDict = {}
      store.idReachDict = {}
      store.idCampaignDict = {}
      store.idPlacementDict = {}
      //store.mmData.custom = {}
      //store.mmData.adjust_custom = {}
      //store.customQuery = {}
      store.percentageOptions = []

      //console.log({store})

      // Add router to push to Login page upon expired session
      // Consider moving router to router.js (currently main.js)
      if (store.mmData) {
        store.session_router = this.$router

        // Adjust sessionRange dates only when outside mmRange
        store.mmData.adjust_custom = false
      }

      // Billing Entity
      const be_options = store.mmData.be_options, beLen = be_options.length;
      for (let r = 0; r < beLen; r++) {
        const tmpRow = be_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idBeDict[tmpId] = tmpName
      }

      // Site Name
      const site_options = store.mmData.site_options, snLen = site_options.length;
      for (let r = 0; r < snLen; r++) {
        const tmpRow = site_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idSnDict[tmpId] = tmpName
      }

      // Device Type
      const device_options = store.mmData.device_options, deviceLen = device_options.length;
      for (let r = 0; r < deviceLen; r++) {
        const tmpRow = device_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idDeviceDict[tmpId] = tmpName
      }

      // Sub-Product
      const subproduct_options = store.mmData.subproduct_options, subproductLen = subproduct_options.length;
      for (let r = 0; r < subproductLen; r++) {
        const tmpRow = subproduct_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idSubProductDict[tmpId] = tmpName
      }

      // Demographic
      const demo_options = store.mmData.demo_options, demoLen = demo_options.length;
      for (let r = 0; r < demoLen; r++) {
        const tmpRow = demo_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idDemoDict[tmpId] = tmpName
      }

      // Country
      const country_options = store.mmData.country_options, countryLen = country_options.length;
      for (let r = 0; r < countryLen; r++) {
        const tmpRow = country_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idCountryDict[tmpId] = tmpName
      }

      // Language
      const lang_options = store.mmData.lang_options, langLen = lang_options.length;
      for (let r = 0; r < langLen; r++) {
        const tmpRow = lang_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idLangDict[tmpId] = tmpName
      }

      // Platform
      const platform_options = store.mmData.platform_options, platformLen = platform_options.length;
      for (let r = 0; r < platformLen; r++) {
        const tmpRow = platform_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idPlatformDict[tmpId] = tmpName
      }

      // Reach
      //const reach_options = store.mmData.reach_options, reachLen = reach_options.length;
      const reach_options = [{value:1,label:'Broad'},{value:2,label:'Normal'},{value:3,label:'Narrow'}], reachLen = reach_options.length;
      for (let r = 0; r < reachLen; r++) {
        const tmpRow = reach_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idReachDict[tmpId] = tmpName
      }

      // Campaign
      //const campaign_options = store.mmData.campaign_options, campaignLen = campaign_options.length;
      const campaign_options = [{value:1,label:''}], campaignLen = campaign_options.length;
      for (let r = 0; r < campaignLen; r++) {
        const tmpRow = campaign_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idCampaignDict[tmpId] = tmpName
      }

      // Placements
      //const placement_options = store.mmData.placement_options, placementLen = placement_options.length;
      const placement_options = [{value:'all',label:'All'}], placementLen = placement_options.length;
      for (let r = 0; r < placementLen; r++) {
        const tmpRow = placement_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idPlacementDict[tmpId] = tmpName
      }

      // Generate Percentages
      for(let i = 5; i <= 100; i += 5) {
        const label = `${i}%`, value = i;

        store.percentageOptions.push({label, value});
      }

      console.log({store})
    }
  }
</script>
<style>
.row select {
    width: 100%;
}
.card-category, .category {
    text-transform: none;
}
.mundialBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.mundialHeader {
    border-top: none;
    margin-top: -1em;
    margin-bottom: 3em;
}
.mundial-default.el-select .el-input input {
    background-color: #fff;
    border-color: #ddd!important;
    border-width: 2px;
    border-radius: 12px;
    color: #66615b;
}
.el-date-editor .el-range__close-icon {
    display: none;
}
.select-default.el-select .el-input .el-input__icon {
    border-radius: 20px;
    height: 36px;
    width: 30px;
    line-height: 36px;
}
.select-default.el-select-dropdown__item.selected, .select-default.el-select-dropdown__item.selected.hover {
    background-color: #ddd;
    color: #66615b;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover {
    border-color: #9a9a9a;
}
.daterange-mundial {
    border-radius: 12px !important;
    border: 2px solid #ddd !important;
}
.mundial-vcenter {
    display: table;
}
.mundial-vcenter button {
    margin-top: 1em;
}
.mundial-vcenter > .mundial-a {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.mundial-vcenter > .btn-mundial-reset {
    float: right;
}
.btn-mundial-reset:hover, .btn-mundial-reset:focus {
    background-color: #66615b !important;
}
.btn-mundial-info:hover, .btn-mundial-info:focus {
    background-color: #51bcda !important;
}
.btn-mundial-success:hover, .btn-mundial-success:focus {
    background-color: #6bd098 !important;
}
</style>
